import * as React from 'react';

export default function Abs() {
    return (
        <div className="detail">
            <script data-ad-client="ca-pub-5780950584466881" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
            <div className="info">
                <h4 className="title text-uppercase">Ads</h4>
                <div className="content">
                    <p>Putting some random google ads here to cover the server hosting cost </p>
                </div>
            </div>
        </div>
    );
}
